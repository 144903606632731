<template>
  <div>
    <div
      class="relative grid w-full grid-cols-12 gap-x-4 overflow-hidden"
      :class="{
        'max-h-[305px] sm:max-h-max': !isExpanded,
      }"
    >
      <div
        class="relative col-span-4 sm:col-span-4 sm:row-span-3 lg:row-span-5"
      >
        <MoviePosterImage
          :image-object
          :image-config
          :rounded="ROUNDED_TYPE.NONE"
        />

        <div class="absolute left-0 top-0 aspect-[2/3] w-full">
          <Button
            v-if="movie.hasTrailers"
            :theme="BUTTON_THEME.TRANSPARENT"
            :size="BUTTON_SIZE.LG"
            :title="t('btn.playTrailer')"
            class="absolute inset-0 z-10 h-full w-full"
            @click="isTrailerModalVisible = true"
          >
            <InlineSvg
              :name="SVG_ICON.PLAY_TRAILER"
              class="h-full w-full sm:mx-8 lg:mx-12 xl:mx-16"
            />
          </Button>
        </div>

        <div
          v-if="!hideDate && movie.released"
          class="bg-secondary text-on-secondary break-words text-center text-sm font-bold uppercase"
        >
          <span class="pr-1" v-text="t('text.from')" />
          <i18n-d
            tag="span"
            :value="new Date(movie.released)"
            :format="{ day: '2-digit', month: '2-digit' }"
          />
        </div>
      </div>

      <div class="col-span-8 col-start-5 self-end sm:col-start-5">
        <div
          class="text-balance break-words pb-4 text-xl font-bold leading-tight sm:text-2xl"
          v-text="movie.title"
        />

        <div
          v-for="(entry, entryIndex) in quickFacts"
          :key="`entry-${entryIndex}`"
          class="space-x-1 text-sm sm:text-base"
        >
          <span class="font-bold after:content-[':']" v-text="t(entry.label)" />
          <span v-html="entry.content" />
        </div>
      </div>

      <div
        class="col-span-12 py-6 text-base lg:col-span-8 lg:col-start-5 lg:row-start-4"
        v-html="movie.description"
      />

      <div
        class="col-span-6 space-y-3 sm:col-span-8 sm:col-start-5 sm:row-start-2 sm:space-y-0"
      >
        <div
          v-for="(entry, entryIndex) in moreDetails"
          :key="`entry-${entryIndex}`"
          class="text-sm sm:space-x-1 sm:text-base"
        >
          <span
            class="block font-bold sm:inline sm:after:content-[':']"
            v-text="t(entry.label)"
          />
          <span v-text="entry.content" />
        </div>
      </div>

      <div class="col-span-6 sm:col-span-8 sm:col-start-5 sm:row-start-3">
        <div
          v-for="(entry, entryIndex) in cast"
          :key="`entry-${entryIndex}`"
          class="text-sm sm:space-x-1 sm:text-base"
        >
          <span
            class="block font-bold sm:inline sm:after:content-[':']"
            v-text="t(entry.label)"
          />
          <span v-text="entry.content" />
        </div>
      </div>

      <div
        v-if="!isExpanded"
        class="dark:to-dark to-light absolute bottom-0 h-6 w-full bg-gradient-to-b from-transparent sm:hidden"
      />
    </div>

    <button
      class="w-full py-2 text-sm underline sm:hidden"
      @click="isExpanded = !isExpanded"
      v-text="isExpanded ? t('btn.showLess') : t('btn.showMore')"
    />

    <slot name="detail-link" />

    <LazyYoutubeModal
      v-if="isTrailerModalVisible"
      :remote-video-id="movie?.trailers?.[0]?.remoteVideoId"
      @close="isTrailerModalVisible = false"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Movie, MovieInterface } from '#gql/default'

interface Props {
  movie: Movie | MovieInterface
  hideDate?: boolean
}

const { t } = useI18n()
const props = defineProps<Props>()

const imageObject = getImage(props.movie, 'thumbnailImage', props.movie.title)
const imageConfig = computed(() => ({
  width: 300,
  preload: false,
  fit: IMAGE_FIT.STRETCH,
  loading: IMAGE_LOADING.LAZY,
  sizes: 'sm:112px md:176px lg:352px xlg:352px',
}))

const isExpanded = ref(false)
const isTrailerModalVisible = ref(false)

const quickFacts = computed(() =>
  [
    {
      label: 'label.duration',
      content: `${props.movie.duration} ${t('text.minutes')}`,
    },
    props.movie.contentRating && {
      label: 'label.rating',
      content: formatContentRating(props.movie.contentRating),
    },
    props.movie.genres && {
      label: 'label.genre',
      content: joinArrayOfObjectsByAttribute(props.movie.genres, 'name', 2),
    },
  ].filter(Boolean)
)

const moreDetails = computed(() =>
  [
    props.movie.titleOriginal && {
      label: 'label.titleOriginal',
      content: props.movie.titleOriginal,
    },
    props.movie.productionYear && {
      label: 'label.production',
      content: props.movie.productionYear,
    },
    props.movie.distributor && {
      label: 'label.distributor',
      content: props.movie.distributor,
    },
    props.movie.publisherUrl && {
      label: 'label.distributor',
      content: props.movie.publisherUrl,
    },
  ].filter(Boolean)
)

const cast = computed(() =>
  [
    props.movie.actors && {
      label: 'label.actor',
      content: joinArrayOfObjectsByAttribute(props.movie.actors, 'name', 5),
    },
    props.movie.directors && {
      label: 'label.director',
      content: joinArrayOfObjectsByAttribute(props.movie.directors, 'name', 3),
    },
  ].filter(Boolean)
)

defineOptions({
  name: 'MovieListItem',
})
</script>

<i18n>
de:
  label:
    production: "Produktion"
    director: "Regie"
    actor: "Darsteller"
    genre: "Genre"
    duration: "Dauer"
    rating: "Altersfreigabe"
    titleOriginal: "Originaltitel"
    distributor: "Verleih"
  text:
    from: "ab"
    minutes: "Minuten"
  btn:
    tickets: "Tickets"
    showMore: "mehr anzeigen"
    showLess: "weniger anzeigen"
    playTrailer: "Trailer abspielen"
es:
  label:
    production: "Producción"
    director: "Director"
    actor: "Actor"
    genre: "Género"
    duration: "Duración"
    rating: "Clasificación"
    titleOriginal: "Título original"
    distributor: "Distribuidor"
  text:
    from: "desde"
    minutes: "minutos"
  btn:
    tickets: "Entradas"
    showMore: "mostrar más"
    showLess: "mostrar menos"
    playTrailer: "Reproducir tráiler"
</i18n>
